import DateFnsUtils from '@date-io/date-fns'
import { Button, Container, createStyles, Grid, Typography } from '@material-ui/core'
import { DataGrid, GridColDef, GridOverlay, GridValueFormatterParams } from '@material-ui/data-grid'
import PrintIcon from '@material-ui/icons/Print'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/styles'
import { Sale, User } from '@yodo/types'
import { frCH } from 'date-fns/locale'
import firebase from 'firebase/app'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Loading from 'src/components/Molecules/Loading'
import commonStyles from 'src/styles/commonStyles'
import { formatStringToDate } from 'src/utils/libs/date'

type Props = {
  sales: any[]
}

export default function StatementList({ sales }: Props) {
  const commonClasses = commonStyles()
  const [loading, setLoading] = useState(true)
  const [displaySales, setDisplaySales] = useState<Array<any>>([])
  const [totalSales, setTotalSales] = useState<number>(0)
  const [totalCommissions, setTotalCommissions] = useState<number>(0)
  const [fromDate, setFromDate] = useState<Date>(new Date())
  const [toDate, setToDate] = useState<Date>(new Date())

  const gridWrapperRef = useRef<HTMLDivElement>(null)
  const classes = useStyles(gridWrapperRef)

  useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current
    if (gridDiv) {
      const gridEl: HTMLDivElement = gridDiv.querySelector('div')!
      if (gridEl) {
        gridEl.style.height = ''
      }
    }
  })

  useEffect(() => {
    const transform = async () => {
      let _sales: any[] = []
      let _totalSales: number = 0
      let _totalCommissions: number = 0
      await Promise.all(
        sales.slice().map(async (sale) => {
          const saleData = sale.data() as Sale
          const saleDate = new Date(saleData.createdAt.seconds * 1000)
          if (saleDate < fromDate || saleDate > toDate) {
            return
          }

          const customer = await firebase
            .firestore()
            .collection('users')
            .doc(saleData.customer.id)
            .get()

          let customerPhoneNumber: string | undefined = ''
          if (customer.exists) {
            const customerData = customer.data() as User
            customerPhoneNumber = customerData.phoneNumber
          }

          let reimbursementDate: string | undefined = ''
          if (saleData.reimbursementDate) {
            reimbursementDate = formatStringToDate(
              new Date(saleData.reimbursementDate.seconds * 1000).toISOString(),
              {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              }
            )
          }

          const commission: number = saleData.priceTotalCustomer! - saleData.priceTotalMerchant!

          // Add to total
          _totalSales = _totalSales + saleData.priceTotalCustomer!
          _totalCommissions = _totalCommissions + commission

          const rowValues = {
            id: sale.id,
            dealName: `(${saleData.quantity}) ${saleData.dealName}`,
            customerPhoneNumber: customerPhoneNumber,
            quantity: saleData.quantity,
            createdAt: formatStringToDate(saleDate.toISOString(), {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            }),
            priceTotalMerchant: saleData.priceTotalMerchant,
            priceTotalCustomer: saleData.priceTotalCustomer,
            priceUnitRegular: saleData.priceUnitRegular! * saleData.quantity,
            reimbursementDate: reimbursementDate,
            commission
          }
          _sales.push(rowValues)
        })
      )
        .then(() => {
          setDisplaySales(_sales)
          setTotalSales(_totalSales)
          setTotalCommissions(_totalCommissions)
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    }

    if (sales && sales.length) {
      // transform
      setLoading(true)
      transform()
    }
  }, [sales, fromDate, toDate])

  useEffect(() => {
    const currentDate = new Date()
    var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
    setFromDate(firstDay)
    setToDate(lastDay)
  }, [])
  if (loading) {
    return <Loading />
  }

  const columns = getColumnDefinition()

  const fromDateString = formatStringToDate(fromDate.toISOString(), {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })

  const toDateString = formatStringToDate(toDate.toISOString(), {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })
  return (
    <>
      <Container className={commonClasses.mainContentContainer}>
        <Typography style={{ textAlign: 'right', marginBottom: 45 }} className={classes.text}>
          {`Le ${formatStringToDate(new Date().toISOString(), {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })}`}
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frCH}>
          <Grid container direction="row" alignItems="center" style={{ marginBottom: 15 }}>
            <Grid item xs={2} className={classes.dateSelector}>
              <KeyboardDatePicker
                value={fromDate}
                onChange={(_date) => setFromDate(_date!)}
                cancelLabel="Annuler"
                label={null}
                format={'dd.MM.yyyy'}
                maxDate={toDate}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true
                }}
              />
            </Grid>
            <Grid
              item
              xs={8}
              style={{ display: 'flex' }}
              direction={'column'}
              alignItems={'center'}>
              <Typography variant={'subtitle1'} style={{ textAlign: 'center', marginBottom: 10 }}>
                {`Décompte des achats du ${fromDateString} au ${toDateString}`}
              </Typography>
              <Button variant={'outlined'} onClick={() => window.print()}>
                <PrintIcon style={{ marginRight: 5 }} />
                Imprimer
              </Button>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }} className={classes.dateSelector}>
              <KeyboardDatePicker
                value={toDate}
                onChange={(_date) => setToDate(_date!)}
                cancelLabel="Annuler"
                label={null}
                format={'dd.MM.yyyy'}
                minDate={fromDate}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true
                }}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Container>
      <div
        ref={gridWrapperRef}
        style={{ width: '95%', margin: 'auto', overflow: 'hidden' }}
        className={commonClasses.bigContentContainer}>
        <div className={classes.root}>
          <DataGrid
            rows={displaySales}
            loading={false}
            columns={columns}
            rowHeight={45}
            headerHeight={100}
            autoHeight={true}
            density="compact"
            disableSelectionOnClick
            checkboxSelection={false}
            hideFooterPagination
            hideFooter
            className={classes.dataGrid}
            components={{
              NoRowsOverlay: () => {
                return (
                  <GridOverlay>
                    <Typography>Aucune vente</Typography>
                  </GridOverlay>
                )
              }
            }}
          />
        </div>
      </div>

      <Container className={commonClasses.mainContentContainer} style={{ marginTop: 5 }}>
        <Typography
          variant="h5"
          style={{ textAlign: 'right', marginBottom: 10, marginTop: 30 }}
          className={classes.bottomText}>
          Total des articles achetés du {fromDateString} au {toDateString} :{' '}
          {formatPrice(totalSales)}
        </Typography>
        <Typography variant="h5" style={{ textAlign: 'right' }} className={classes.bottomText}>
          Total des commissions payées par les clients du {fromDateString} au {toDateString} :{' '}
          {formatPrice(totalCommissions)}
        </Typography>
      </Container>
    </>
  )
}

function formatPrice(price: any) {
  return (
    'CHF ' +
    Number(price)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'")
  )
}

function getColumnDefinition(): GridColDef[] {
  let columnsDefinition = [
    {
      field: 'dealName',
      headerName: 'Offre',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    },
    {
      field: 'createdAt',
      headerName: "Date d'achat",
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    },
    {
      field: 'priceUnitRegular',
      headerName: 'Prix avant offre',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{formatPrice(params.value)}</Typography>
      }
    },
    {
      field: 'priceTotalMerchant',
      headerName: "Prix exclusif, j'encaisse",
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{formatPrice(params.value)}</Typography>
      }
    },
    {
      field: 'priceTotalCustomer',
      headerName: 'Prix affiché pour le client',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{formatPrice(params.value)}</Typography>
      }
    },
    {
      field: 'commission',
      headerName: 'Commission payée par le client',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{formatPrice(params.value)}</Typography>
      }
    },
    {
      field: 'reimbursementDate',
      headerName: 'Remboursement',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    }
  ]

  return columnsDefinition
}

const useStyles = makeStyles(() =>
  createStyles({
    bottomText: {},
    dateSelector: {},
    dataGrid: {},
    text: {},
    root: {
      '& div.MuiDataGrid-window': {
        overflow: 'hidden',
        borderWidth: 3,
        borderColor: 'red'
      },
      '& div.MuiDataGrid-cell, & div.MuiDataGrid-columnHeader': {
        flex: 1,
        minWidth: 'calc(100vw/7)!important',
        maxWidth: 'calc(100vw/7)!important',
        '&:last-child': {}
      },
      '& div.cell-small': {
        flex: 1,
        minWidth: '100px!important',
        maxWidth: '100px!important',
        '&:last-child': {}
      },
      '& p.MuiTypography-root': {}
    },
    [`@media print`]: {
      bottomText: {
        color: 'black',
        fontSize: 12
      },
      dateSelector: {
        opacity: '0'
      },
      dataGrid: {
        color: 'black',
        overflow: 'hidden',
        width: '100%'
      },
      text: { color: 'black' },
      root: {
        '& div.MuiDataGrid-iconButtonContainer': {
          display: 'none!important'
        },
        '& div.MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'no-wrap!important',
          textOverflow: 'unset'
        },
        '& div.MuiDataGrid-cell, & div.MuiDataGrid-columnHeader': {
          minWidth: 'calc(26cm/7)!important',
          maxWidth: 'calc(26cm/7)!important',
          fontSize: 10,
          lineHeight: '10px',
          padding: '0 3px!important'
        },
        '& div.MuiDataGrid-columnHeader': {
          padding: '3px'
        },
        '& div.cell-small': {
          minWidth: '2cm!important',
          maxWidth: '2cm!important'
        },
        '& p.MuiTypography-root': {
          fontSize: '10px',
          lineHeight: '10px'
        }
      }
    }
  })
)
