import { Typography } from '@material-ui/core'
import { config } from '@yodo/config'
import { Sale } from '@yodo/types'
import React, { useContext } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import Loading from 'src/components/Molecules/Loading'
import StatementList from 'src/components/Views/Statement/statementList'
import { FirebaseContext } from 'src/utils/firebase'

type Props = {}
export default function StatementView({}: Props) {
  const { firebase, token } = useContext(FirebaseContext)
  const merchantId = token?.claims?.merchantId ?? null

  // Fetch all user integrity reports
  const [sales, saleLoading] = useCollection<Sale>(
    // @ts-ignore
    firebase
      .firestore()
      .collection(config.collection('sales'))
      .orderBy('createdAt', 'desc')
      .where(
        'merchant',
        '==',
        firebase
          .firestore()
          .doc(`${config.collection('merchants')}/${merchantId === '' ? undefined : merchantId}`)
      )
      .where('state', 'in', ['used', 'payed']),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  )

  if (saleLoading) {
    return <Loading />
  }

  if (!sales?.docs.length) {
    return <Typography>Aucune vente effectuée</Typography>
  }

  return (
    <div>
      <StatementList sales={sales.docs} />
    </div>
  )
}
