import React, { ReactElement } from 'react'
import Layout from 'src/components/Layout'
import AppFooter from 'src/components/Molecules/Footer'
import TopBar from 'src/components/Molecules/TopBar'
import Page from 'src/components/Page'
import PrivateRoute from 'src/components/PrivateRoute'
import StatementView from 'src/components/Views/Statement'

export default function StatementPage(): ReactElement {
  return (
    <Layout>
      <Page>
        <PrivateRoute path="/login">
          <TopBar />
          <StatementView />
          <AppFooter />
        </PrivateRoute>
      </Page>
    </Layout>
  )
}
